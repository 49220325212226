import React from 'react';
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import { Grid, Container, Button, Icon } from 'semantic-ui-react';

import Nav from './Nav';
import AboutJoey from './AboutJoey';
import About from './About';
import Roadmap from './Roadmap';
import Team from './Team';

import { device } from '../device.js';
import FAQs from './FAQs';
import Footer from '../Components/Footer';
import CTAButton from '../Components/CTAButton';
import FormattedAccount from '../Components/FormattedAccount'

const Home = (props)=>{
    return (
        <div className={`${props.className}`}>
            <Grid id="home">
                <Grid.Row>
                    <Grid.Column computer="10" mobile="16" id="header-image-row">
                        <div id="header-image">
                           <img src="/header.png"/>
                        </div>
                    </Grid.Column>
                    <Grid.Column computer="6" mobile="16">
                        <center id="intro">
                            <h1><span>5005</span></h1>
                            <h2>Unique NFTs <br/><span>0.046</span> ETHs</h2>
                            <CTAButton/>
                            
                            <a href="https://twitter.com/crazyjoeynft" target="_blank">
                                <Button className='social-button'>
                                    <Icon name="twitter"/> Follow on Twitter
                                </Button>
                            </a>
                            
                            <a href="https://opensea.io/collection/crazyjoeynft" target="_blank">
                                <Button className='social-button'>
                                    <Icon name="external alternate"/> View in OpenSea
                                </Button>
                            </a>
                        </center>
                    </Grid.Column>                                     
                </Grid.Row>                    
            </Grid>           
            <AboutJoey/>    
            <About/> 
            <Roadmap/>
            <Team/>
            <FAQs/>
        </div>
    )
}

export default styled(Home)`
    #home {
        background-image: linear-gradient(to bottom, #0095e1, #8282e5, #cd61c6, #f83d88, #f9423a);        min-height: 90vh;
        margin: 0px;
    }    

    #header-image {
        img {
            width: 100%;
            margin: 10%;
        }
    }

    #intro {
        margin: 50% 10% !important;
        
        h1 {
            font-family: 'Titillium Web', sans-serif;
            font-weight: light !important;
            font-size: 120px;
            color: yellow;
            line-height: 90px;
            -webkit-text-stroke: 5px black; /* width and color */
            span {
                margin-right: 5px;
            }
        }
    
        h2 {
            font-size: 50px;
            font-family: 'Titillium Web', sans-serif;
            color: white;
            -webkit-text-stroke: 1px black;

            span {
                color: yellow;
                -webkit-text-stroke: 2px black; /* width and color */
            }
        }

        button {
            border-radius: 25px;
            font-size: 17px;
            background-color: white;
            color: black;
            border: 2px solid black;
            animation: nudge .4s linear;
            animation-delay: 1s;
            min-width: 250px;
            margin: 2px;
        }

        .social-button {
            background: inherit;
            font-size: 17px;
        }

        button: hover {
            background-color: #a0f404;
        }        
    }

    @media ${device.tablet} {  
        #header-image-row { 
            height: 200px !important; 
            img{
                margin: 5% !important;
                width: 90%;
            }
        }

        #intro {
            margin: 5% 5% !important;

            h1 {
                font-size: 100px;
            }

            h2 {
                font-size: 40px !important;
            }
       }
    }

    @media (min-width: 1400px) {
        #home {
            min-height: 100vh !important;
        }
    }
`;