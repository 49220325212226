import React from 'react';
import styled from 'styled-components'

import { Grid, Container, Button, Icon, Card } from 'semantic-ui-react';
import { device } from '../device.js';
import { SocialIcon } from 'react-social-icons';

const Footer = (props)=>{
    return (
        <Grid stackable className={props.className}>
            <Grid.Row>
                <Grid.Column>
                    <center>
                        © 2022 Crazy Joey All rights reserved.<br/> 
                        <div className='social-icons-container'>                                               
                            <SocialIcon url="https://twitter.com/crazyjoeynft" bgColor="black"  target="_blank" />
                            <SocialIcon url="https://discord.gg/gSZcDdk7Ew"   bgColor="black" target="_blank" />
                            <SocialIcon url="mailto:support@crazyjoeynft.com"  bgColor="black" target="_blank" />
                        </div>  
                    </center>
                </Grid.Column>
            </Grid.Row>
        </Grid>
  );
}

export default styled(Footer)`
    background: white !important;
    padding: 5px 15px !important;
    font-size: 15px;
    text-transform: uppercase;
    
    .icon {
        margin: 5px;
        font-size: 30px;
    }

    .icon:hover {
        color: blue;
    }

    @media ${device.mobileL} {   
        font-size: 12px;         
    }

    .social-icons-container {
        padding: 10px;
    }

    .social-icon {
        margin: 3px !important;
        width: 30px !important;
        height: 30px !important;
    }
`;