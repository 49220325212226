import React from 'react';
import styled from 'styled-components'
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { ethers } from 'ethers';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import FormattedAccount from '../Components/FormattedAccount';

import {
    Button, Icon, Loader, Dimmer
} from 'semantic-ui-react'

import contract from '../Contracts/CrazyJoey.json';
import walletSlice from '../Redux/slices/wallet'
import { toast } from 'react-toastify';

export const NETWORK = 'mainnet'
export const MAX_PER_MINT = 10;
export const PRICE = "0.046";
export const abi = contract.abi;
export const contractAddress = "0xEF78f366b73EB876e7565B077af2580C3266fEe1";
export const openseaBaseUrl = "https://opensea.io";

const providerOptions = {
    walletconnect: {        
        package: WalletConnectProvider,
        options: {
          infuraId: "226405e9193844c892df96beb3df0fac" 
        }
    }
}

const web3Modal = new Web3Modal({ 
    providerOptions,
    cacheProvider: true, 
    network: NETWORK 
});  

const CTAButton = (props)=>{
    const dispatch = useDispatch();
    const [wallet, setWallet] = useState();
    const [count, setCount] = useState(1);
    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState();
    const [chainId, setChainId] = useState();
    const [minting, setMinting] = useState(false);


    const registerEvents = (provider) => {
        provider.on('chainChanged', (id)=>{
            window.location.reload();
        })

        provider.on('accountsChanged', (accounts)=>{
            setWallet(accounts[0]);
        })
    }

    const connectWalletHandler = async () => {    
        try{     
            const provider = await web3Modal.connect();  
            setProvider(provider);
            registerEvents(provider);

            const library = new ethers.providers.Web3Provider(provider);
            setLibrary(library);

            const accounts = await library.listAccounts();
            setWallet(accounts[0])                    
            toast.success("Wallet successfully connected")

            const { chainId } = await library.getNetwork();
            setChainId(chainId);
            if(chainId != 1) toast.warn("Network is not Ethereum Mainnet");
        }catch(e){
            console.error(e);
            toast.error("Could not connect to wallet");
        }        
    }

    const mintHandler = async () => {         
        try{
            if(chainId != 1 && NETWORK === "mainnet") return toast.warn("Network is not Ethereum Mainnet");                    
            const nftContract = new ethers.Contract(contractAddress, abi, library.getSigner());                            
            
            setMinting(true);
            let nftTxn = await nftContract.mint(count, { value: ethers.utils.parseEther(`${count * PRICE}`) });    
            await nftTxn.wait();
            console.log(nftTxn);
            setMinting(false);
            
            toast.success(
                <span>
                    <span>Successfully minted {count} NFT{count > 1? 's' : ''}. </span>
                    <span><b><a target="_blank" href={`${openseaBaseUrl}/account`}>View in OpenSea</a></b></span>
                </span>
            , { autoClose: false, closeOnClick: false }); 
        }catch(e){
            setMinting(false);
            if(e.code == "INSUFFICIENT_FUNDS"){
                toast.error(`Please make sure you have ${count * PRICE} ethers + gas in your wallet`);
            } else if(e.code == 4001){
                toast.error("Transaction rejected by user")
            } else {
                toast.error("Something went wrong");
                console.error(e);
            }
        }
    }

    const countIncrementHandler = ()=>{
        if(count < MAX_PER_MINT){
            setCount(count+1)
        }
    }

    const countDecrementHandler = ()=>{
        if(count > 1){
            setCount(count-1)
        }
    }

    const renderMintButton = ()=>{
        return (
            <div>
                <div id="nft-count">
                    <Icon name="minus circle" onClick={countDecrementHandler} disabled={count <= 1}/> 
                    <span id='mint-count' disabled={true}>{count}</span>
                    <Icon name="add circle" onClick={countIncrementHandler} disabled={count >= 10}/>
                </div>
                <Button onClick={mintHandler} className='cta-button mint-button'>
                    <Dimmer active={minting}>
                        <Loader> Transaction In Process </Loader>
                    </Dimmer>
                    Mint NFT
                </Button>
                <FormattedAccount account={wallet}/>
            </div>
        );
    }

    const renderConnectButton = ()=> {
        if(NETWORK === 'mainnet' || localStorage.getItem('cz_test')){
            return (
                <Button onClick={connectWalletHandler} className='cta-button connect-button'>
                    Connect Wallet
                </Button>
            );
        }
        return (<Button> Mintable Soon </Button>)
    }

    return (
        <div className={props.className}>
            { wallet? renderMintButton() : renderConnectButton() }
        </div>
    )
}

export default styled(CTAButton)`
    #nft-count {
        color: #9ef542;
        -webkit-text-stroke: 1px black; /* width and color */
        padding: 20px;
        
        .icon {
            color: white;
            margin: 5px;
            font-size: 30px;
        }

        .icon:hover {
            color: #9ef542;
            cursor: pointer;
        }
    }

    #mint-count {
        font-size: 40px;
        margin: 10px;
        font-family: 'Titillium Web', sans-serif;
    }
`