import React from 'react';
import styled from 'styled-components'

import { Grid, Container, Button, Icon, Card } from 'semantic-ui-react';

import { device } from '../device.js';

import { Bounce } from "react-awesome-reveal";
import { SocialIcon } from 'react-social-icons';

const Member = (props)=>{
    return (
        <Bounce>
            <div className={props.className} style={{background: 'white'}}>
                <center>
                    <img src={props.image} style={{background: props.imageBgColor}}/>
                    <div className="content">
                        <h1>{props.name}</h1>
                        <div className='social'>
                            { props.linkedIn && <SocialIcon url={props.linkedIn} bgColor="#4B3781"  target="_blank" /> }
                            { props.twitter && <SocialIcon url={props.twitter}   bgColor="#0095E1" target="_blank" /> }
                            { props.instagram && <SocialIcon url={props.instagram}  bgColor="#F8413F" target="_blank" /> }
                        </div>
                        <p>{props.about}</p>
                    </div>
                </center>
            </div>
        </Bounce>
  );
}

export default styled(Member)`
    width: 400px;
    max-width: 100%;
    border-radius: 30px;
    box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
    
    img {
        width: 50%;
        margin: 10%;
        margin-bottom: 2%;
        border-radius: 50%; 
        border: 2px solid black;      
    }

    h1 {
        font-family: 'Montserrat', sans-serif !important;
        font-weight: 50 !important;
        font-size: 25px !important;
    }

    p {
        font-size: 18px;
        margin: 20px;
        font-family: 'Montserrat', sans-serif;
        text-align: left;
    }

    .social {
        .social-icon {
            margin: 3px !important;
            margin-top: 0px !important;
            width: 40px !important;
            height: 40px !important;
        }
    }

    .content {
        background: white;
        padding: 4%;
        margin: 0px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
    }

    @media ${device.mobileL} { 
        h1 {
            font-size: 20px !important;
        }

        p {
            margin: 5px;
            font-size: 15px;
        }

        .content {
            padding: 4%;
            width: 95%
        }
       
        .social {
            .social-icon {                
                width: 35px !important;
                height: 35px !important;
            }
        }
    }
`;