import React from 'react';
import styled from 'styled-components'

import { Grid, Container, Button, Icon, Card } from 'semantic-ui-react';
import Nav from './Nav';
import Member from '../Components/Member';

import { device } from '../device.js';

class Team extends React.Component {
    render(){
        return (
            <div className={`${this.props.className}`}>
                <Grid id="about-joey" stackable centered>
                    <Grid.Row>
                       <Grid.Column width={16}>
                            <center>
                               <h1 id="team-header">Meet the Team</h1>
                            </center>
                        </Grid.Column>                                     
                    </Grid.Row>          
                    <Grid.Row >
                        <Grid.Column width={5}>
                            <Member
                                className="member"
                                image="/isa2.png"
                                name="The Creator"
                                about="Isa is young and talented. Being ONLY 12 years old never stopped him from dreaming big. He has been drawing everything by hand, since the age of 3, and all his artwork is 100% original. His hobbies besides drawing are playing basketball, football, helping those around him, and being kind to everyone he meets."
                                color="#bef1f3"
                                imageBgColor="#7ee4e7"
                                instagram="https://instagram.com/isa.illustrated"
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Member
                                className="member"
                                image="/imraaz.png"
                                name="The Developer"
                                about="Imraaz is a passionate Full-Stack Developer with years of experience in startups and fortune 500 companies alike. He's fully committed himself to the decentralized world of Web 3.0. When he is not coding, he loves to read books, spend time with family, and travel the world with his wife."
                                color="#FFCECC"
                                imageBgColor="#ff6b66"
                                twitter="https://twitter.com/imraaz_rally"
                                linkedIn="https://www.linkedin.com/in/imraazrally/"
                            />
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Member
                                className="member"
                                image="/ifraz.png"
                                name="Big Mo"
                                about="Ifraz, or Big Mo, has a gift of connecting people with their talents and bringing the dreams that they have to life. He is a serial entrepreneur, working in the world of finance and owning multiple restaurants and cafés. He is a determined workaholic that has an addiction to philanthropy on all levels."
                                color="#ABF1B5"
                                imageBgColor="#7ce98c"
                                twitter="https://twitter.com/ifraz10"
                                instagram="https://instagram.com/bigmothemortgageguy"
                            />
                        </Grid.Column>
                    </Grid.Row>          
                </Grid>                
            </div>
        )
    }
}

export default styled(Team)`
    background: #F3FCFD;
    padding: 10%;

    .member {
        height: 670px !important;
    }
    
    #team-header{
        font-size: 70px;
        font-family: 'Titillium Web', sans-serif;
        font-weight: light !important;
        color: white;
        -webkit-text-stroke: 2px black; /* width and color */
        padding-bottom: 3%;
    }

    h1 {
        font-family: 'Montserrat', sans-serif;
    }

    @media ${device.mobileL} { 
        padding: 10% 3%;

        #team-header {
            font-size: 40px;
        } 
        
        .member {
            height: auto !important;
        }
    }

    @media ${device.laptopL}{
        padding: 10% 3%;
    }
`;