import React from 'react';
import styled from 'styled-components'

import { Grid, Container, Button, Icon, Card, Image } from 'semantic-ui-react';
import { device } from '../device.js';

const HowToMintMobile = (props)=>{
    return (
        <div className={props.className}>
           <h3> Step 1: Download and Install MetaMask </h3>
           <p> Watch <a href="https://www.youtube.com/watch?v=KaqXKttX-lA&t=108s">this video tutorial</a>  on setting up a new MetaMask wallet or follow instructions below:</p>
           <ol start="1">
            <li>Go to the App Store (iOS) or Google Play Store (Android) and search for "MetaMask".</li>
            <li>Download and install the MetaMask app.</li>
            <li>Open the app and follow the instructions to set up your wallet. You can create a new wallet or import an existing one.</li>
            <li>Make sure to securely store your recovery phrase in a safe place.</li>
           </ol>

           <h3> Step 2: Purchase Ethereum (ETH) </h3>
            If you have sufficient balance in your wallet, you can skip this step. Otherwise, you can follow the steps below to <i>Purchase Ethereum</i>.
            
            <ol start="5">
                <li>Open the MetaMask app on your mobile device and tap on the "Wallet" icon at the bottom.</li>
                <li>Your Ethereum balance is displayed here; tap on it.</li>
                <li>Tap the "Buy" button to initiate a new purchase.</li>
                <li>Enter the amount of ETH you wish to buy in the provided field. Consider the cost of the NFT and the associated gas fees.</li>
                <li>After entering the amount and choosing your payment method (Credit/Debit reccomended), tap “Get quotes” to see the available purchase options.</li>
                <li>Select "Moonpay" from the list of options.</li>
                <li>Follow the on-screen instructions provided by Moonpay. This usually involves entering your payment details and completing an identity verification process.</li>
                <li>Once the purchase is approved and processed, the ETH will be added to your MetaMask wallet.</li>
            </ol>

            <h3>Step 3: Navigate to Crazy Joey website via MetaMask's In-App Browser</h3>
            <ol start="13">
                <li>Open the MetaMask app on your mobile device.</li>
                <li>Tap on the "Browser" icon at the bottom of the screen to access the MetaMask in-app browser.</li>
                <li>In the browser, enter <i>crazyjoeynft.com</i> and navigate to the page.</li>
            </ol>

            <h3>Step 4: Mint the NFT</h3>
            <ol start="16">
                <li>Once on the NFT minting website within the MetaMask browser, If your wallet is not automatically connected, tap on the "Connect Wallet" button, and then confirm the connection when prompted by MetaMask.</li>
                <li>Select the quantity of NFTs you wish to mint</li>
                <li>Tap on the "Mint" button to initiate the minting process.</li>
                <li>A MetaMask popup should appear, asking for confirmation of the transaction, including the cost and the gas fee.</li>
                <li>Review all transaction details carefully, and if everything is correct, confirm the transaction.</li>
                <li>Wait for the transaction to be processed. This may take a few seconds to several minutes, depending on network congestion.</li>
                <li>Once the transaction is confirmed, your NFT will be minted and made available in OpenSea.</li>
            </ol>
        </div>
    )
}

export default styled(HowToMintMobile)`
    li {
        line-height: 1.5em !important;
    }
`