import React from 'react';
import styled from 'styled-components'

import { Grid, Container, Button, Icon, Card, Image } from 'semantic-ui-react';
import { device } from '../device.js';

const Feature = (props)=>{
    return (
        <div className={props.className}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Image src={props.image}/>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <center>
                            <h1>{props.count}</h1>
                            <p>{props.label}</p>
                        </center>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default styled(Feature)`
    margin: 10px !important;
    margin-top: 50px !important;

    img {
        width: 70px;
        margin-top: 20px;
        margin-left: 15px;
    }

    h1 {
        font-size: 60px !important;
        line-height: 60px !important;
        color: rgb(255,255,255, .7) !important;
    }

    p {
        color: #FAD1CD;
    }

    @media ${device.tablet}{
        margin-bottom: 20px !important;
        img {
            width: 70px !important;
        }

        h1 {
            font-size: 70px !important;
        }
    }
`