import './App.css';
import 'semantic-ui-css/semantic.min.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import styled from 'styled-components';
import Home from './Home/Home';
import Gallary from './Gallary/Gallary';
import Footer from './Components/Footer';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const withToastContainer = (Component)=>{
  return ()=> (
    <div>
      <ToastContainer hideProgressBar={true}/>
      <Component/>
    </div>
  );
}

function App(props) {
  document.title = "Crazy Joey"
  return (
    <div className={props.className}>
      <Router>
        <Switch>
          <Route path="/" exact component={withToastContainer(Home)}/>           
          <Route path="/gallary" exact component={withToastContainer(Gallary)}/>
        </Switch>
        <Footer/>
      </Router>
    </div>
  );
}

export default styled(App)`
  background: white;
  min-height: 100vh;
  --toastify-font-family: 'Montserrat', sans-serif;

  .Toastify__toast{
    background: white !important;
    border: 2px solid black !important;
    border-radius: 30px;
    padding: 15px;    
    margin: 10px;
  }

  --toastify-toast-width: 400px;
`;
