import React from 'react';
import styled from 'styled-components'

import { Grid, Container, Button, Icon, Card, Image } from 'semantic-ui-react';
import { device } from '../device.js';
import { Slide } from "react-awesome-reveal";

import Feature from '../Components/Feature';

class About extends React.Component {
    render(){
        return (
            <div className={`${this.props.className}`}>
                <Grid id="about" stackable>
                    <Grid.Row>
                        <Grid.Column width={9} id="about-creator-col">
                            <center id="about-creator">
                                <h1>Meet the Creator</h1>
                                <p className="content">
                                    <center><img src="/isa.png"/></center>
                                    <br/>
                                     Hi, my name is Isa Illyas! I’m 12 years old and I have a strong passion for drawing. My dream has always been to be a great animator and artist. I began drawing at the age of 3 and have continued ever since.  One of my favorite characters that I created is called “Crazy Joey”. My main goals are to do well in school, get into the School of Visual Arts, and one day have great success with animations and cartoons.                                 
                                </p>
                            </center>                           
                        </Grid.Column> 
                        <Grid.Column width={7}>
                            <center id="animation">                               
                                <Grid stackable>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <Slide>
                                                <Feature image="/body.png" count="27" label="Body Traits"/>
                                                <Feature image="/face.png" count="23" label="Face Traits"/>
                                                <Feature image="/ear.png" count="13" label="Ear Traits"/>
                                                <Feature image="/skin.png" count="4" label="Skins"/>
                                            </Slide>
                                        </Grid.Column>
                                        <Grid.Column width={8}>
                                            <Slide direction="right">
                                                <Feature image="/eye.png" count="11" label="Eye Traits"/>
                                                <Feature image="/hat.png" count="22" label="Head Traits"/>
                                                <Feature image="/background.png" count="13" label="Backgrounds"/>
                                                <Feature image="/expressions.png" count="3" label="Moods"/>
                                            </Slide>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </center>
                        </Grid.Column>                              
                    </Grid.Row>                    
                </Grid>           
            </div>
        )
    }
}

export default styled(About)`     
    #about {
        overflow-x: hidden !important;
  
        ::-webkit-scrollbar { 
            display: none;  /* Safari and Chrome */
        }
        
        padding-bottom: 1%;
        margin: 0px !important;
        background: rgb(255,255,255);
        background: linear-gradient(81deg, white 50%, rgba(255,81,84,1) 20%);
        color: black;
        max-width: 100% !important;

        #about-creator {
            margin: 10%;
            max-width: 600px;
            
            img {
                width: 200px;
                border-radius: 50%;
                background: #BEF1F3;
                border: 2px solid black;
            }

            p {
                margin: 20px;
            }    
        }

        #animation {
            padding: 10%;
            margin-left: auto;
            margin-right: auto;
            .feature {
                img {
                    width: 100px;
                    background: white;
                }
            }
        }

        p {
            font-size: 19px;
            font-family: 'Montserrat', sans-serif;
        }

        h1 {
            font-size: 60px;
            font-family: 'Titillium Web', sans-serif;
            line-height: 90px;
            font-weight: light !important;
            color: white;
            -webkit-text-stroke: 2px black; /* width and color */
        }

        .content {
            padding: 50px;
            border-radius: 30px;  
            color: black;   
            text-align: left;
        }
    }

    @media ${device.tablet} { 
       #about {
           background: rgba(255,81,84,.9);
           padding-top: 0px;

           .column {
             padding: 1% !important;
           }

           h1 {
               font-size: 40px !important;
           }

           p {
               font-size: 18px;               
           }

           #about-creator {
               margin: 2%;
           }

           #about-creator-col {
               background: white;
           }

           .content {
               padding: 10px;
               box-shadow: none !important;
               text-align: left;
           }
       }
    }

    @media ${device.laptopL}{
        h1 {
            font-size: 35px !important;
        }        
    }
`;