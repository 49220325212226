import React from 'react';
import styled from 'styled-components'

import { Grid, Container, Button, Icon, Accordion } from 'semantic-ui-react';
import { device } from '../device.js';
import { Slide } from "react-awesome-reveal";
import { contractAddress, NETWORK } from '../Components/CTAButton';

import HowToMintMobile from '../Components/HowToMintMobile.js';

class FAQs extends React.Component {
    state = { activeIndex: -1 }

    handleClick = (e, titleProps) => {
      const { index } = titleProps
      const { activeIndex } = this.state
      const newIndex = activeIndex === index ? -1 : index
  
      this.setState({ activeIndex: newIndex })
    }

    render(){
        return (
            <div className={`${this.props.className}`}>
                <Grid id="faqs" stackable>
                    <Grid.Row>
                        <Grid.Column>
                            <center>
                                <h1>FAQs</h1>
                                <Accordion styled>
                                    <Accordion.Title
                                    active={this.state.activeIndex === 0}
                                    index={0}
                                    onClick={this.handleClick}
                                    >
                                    <Icon name='chevron circle down' />
                                    What is an NFT?
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeIndex === 0}>
                                        <p> A non-fungible token (NFT) is a unique token (typically representing a digital asset) that isn't interchangable with other tokens. <a href="https://www.forbes.com/advisor/investing/nft-non-fungible-token/" target="_blank">Click here to learn more</a></p>
                                    </Accordion.Content>

                        
                                    <Accordion.Title
                                    active={this.state.activeIndex === 2}
                                    index={2}
                                    onClick={this.handleClick}
                                    >
                                    <Icon name='chevron circle down' />
                                    How do I Mint a Crazy Joey?
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeIndex === 2}>
                                        <HowToMintMobile/>
                                    </Accordion.Content>

                                    <Accordion.Title
                                    active={this.state.activeIndex === 3}
                                    index={3}
                                    onClick={this.handleClick}
                                    >
                                    <Icon name='chevron circle down' />
                                    What is Gas?
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeIndex === 3}>
                                        <p> Gas is used to pay for transactions on the Ethereum blockchain. This is essentially a fee that you pay to compensate the miners for the computational energy spent on processing and validating transactions on the blockchain. Please make sure to have extra ETHs in your wallet to mint a Crazy Joey.</p>
                                    </Accordion.Content>

                                    <Accordion.Title
                                    active={this.state.activeIndex === 4}
                                    index={4}
                                    onClick={this.handleClick}
                                    >
                                    <Icon name='chevron circle down' />
                                    Verified Smart Contract?
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeIndex === 4}>
                                        { NETWORK !== "mainnet" && <p>Definitely, once we are live and ready for minting. Please stay tuned!</p> }
                                        { NETWORK == "mainnet" && <p>Definitely, the address for the verified smart contract is <a href={`https://etherscan.io/address/${contractAddress}`}>{contractAddress}</a> </p>}
                                    </Accordion.Content>
                                    
                                    <Accordion.Title
                                    active={this.state.activeIndex === 5}
                                    index={5}
                                    onClick={this.handleClick}
                                    >
                                    <Icon name='chevron circle down' />
                                    How to reach out for support?
                                    </Accordion.Title>
                                    <Accordion.Content active={this.state.activeIndex === 5}>
                                        <p> You can contact us through discord, twitter, or email us at <a href="mailto:support@crazyjoeynft.com">support@crazyjoeynft.com</a>. Please note that we will never ask for your private keys, pass phrases, recovery phrases, seed phrases, or any sensitive information. Please beware of scammers.  </p>                                        
                                    </Accordion.Content>
                                    
                                </Accordion>
                            </center>
                        </Grid.Column>
                    </Grid.Row>               
                </Grid>           
            </div>
        )
    }
}

export default styled(FAQs)`  
    padding-bottom: 5%;
    #faqs {        
        background: white;
        min-height: 50vh;
        padding: 5%;
        color: black;

        h1 {
            font-size: 70px;
            font-family: 'Titillium Web', sans-serif;
            line-height: 90px;
            font-weight: light !important;
            color: white;
            -webkit-text-stroke: 2px black; /* width and color */
        }

        .social-icon {
            margin: 3px !important;
            width: 40px !important;
            height: 40px !important;
        }

        .accordion {
            padding: 5%;
            width: 100% !important;
            border: none !important;
            box-shadow: none !important;
            background: white;

            .icon {
                margin-right: 5px;
            }

            .title {
                font-family: 'Montserrat', sans-serif !important;
                font-size: 20px;
                border: none !important;
                text-align: left;
                color: black;
                font-weight: 200 !important;
                line-height: 1.5em;
                text-transform: uppercase;
            }

            .content {
                font-family: 'Montserrat', sans-serif !important;
                font-size: 18px;
                text-align: left;
                padding: 5% 5%;
                color: black;
                font-weight: 200 !important;
                background: rgb(242,242,242,.5);
            }
        }
    }

    @media ${device.mobileL} { 
       #faqs {  
           padding: 1%;
           
           h1 {
               font-size: 40px;
           }  
           
           .accordion {
               padding: 0%;
               .title {
                   font-size: 15px;
               }

               .content {
                   font-size: 15px;
               }
           }
       }
    }
`