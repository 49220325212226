import React from 'react';
import styled from 'styled-components'
import { Grid, Container, Button, Icon } from 'semantic-ui-react';

const FromattedAccount = (props)=>{
    const account = props.account;
    if(!account) return <div></div>
    
    return (
        <div id="connected-wallet" className={props.className}>
            <Icon name="linkify"/> Connected to&nbsp;
            {account.substring(0,5)}....{account.substring(account.length-4)}
        </div>
    )         
}

export default styled(FromattedAccount)`
    padding: 8px;
    color: white;
    font-size: 18px;  
    .icon {
        color: #cbf745 !important;
        font-size: 25px;
    }  
`