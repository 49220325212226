import React from 'react';
import styled from 'styled-components'

import { Grid, Container, Button, Icon, Menu } from 'semantic-ui-react';

class Nav extends React.Component {
    state = {}

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render(){
        const { activeItem } = this.state

        return (
            <div className={`${this.props.className}`}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={16}>
                        <Menu>
                            <Menu.Item
                                name='editorials'
                                active={activeItem === 'editorials'}
                                onClick={this.handleItemClick}
                                >
                                    <img src="logo.png"/>
                            </Menu.Item>                            
                        </Menu>
                        </Grid.Column>                                    
                    </Grid.Row>                    
                </Grid>                
            </div>
        )
    }
}

export default styled(Nav)`
    .menu {
        background: #49b6e9 !important;
        border: 0px !important;
    }
    img {
        width: 300px !important;
    }
`;