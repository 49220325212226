import { createSlice } from '@reduxjs/toolkit'

export const wallet = createSlice({
  name: 'wallet',
  initialState: {
    account: undefined,
    transactionInProcess: false
  },
  reducers: {
    connect: (state, action) => {  
      state.account = action.payload.account
    },
    disconnect: (state) => {
      state.account = undefined
    }, 
    transact:(state) => {
      state.transactionInProcess = true
    },  
    endTransaction: (state)=> {
      state.transactionInProcess = false
    }     
  },
})

export default wallet