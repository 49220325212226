import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { Grid, Container, Button, Icon } from 'semantic-ui-react';
import { SocialIcon } from 'react-social-icons';

import { device } from '../device.js';

class AboutJoey extends React.Component {
    render(){
        return (
            <div className={`${this.props.className}`}>
                <Grid id="about-joey">
                    <Grid.Row>
                        <Grid.Column computer="8" mobile="16" id="about-collection">
                            <center>
                                <h1> Crazy <span>Joey</span> </h1>
                                <p>Crazy Joey is a hilarious collection of NFTs on the Ethereum blockchain created by a <span>12 year old digital artist, Isa.</span> The collection consists of 5005 hand-drawn randomly assembled Crazy Joeys from over 500K total options.</p>
                                <Link to="/gallary">
                                    <Button> View Gallary </Button>
                                </Link>                                
                            </center>
                        </Grid.Column>
                        <Grid.Column computer="8" mobile="16" id="collection">
                           <Grid>
                               <Grid.Row>
                                    <div>
                                        <img src="/animation.gif"/>
                                    </div>                           
                               </Grid.Row>                              
                           </Grid>
                        </Grid.Column>                                     
                    </Grid.Row>                    
                </Grid>                
            </div>
        )
    }
}

export default styled(AboutJoey)`
    background-color: white;
    padding-top: 5%;
    padding-bottom: 0%;

    #about-joey {
        margin: 0px;

        #collection {
    
            img {
                max-width: 60% !important;
                margin: 5% !important;
                border-radius: 50%;
            }
                      
        }

        button {
            border-radius: 25px;
            font-size: 20px;
            background-color: white;
            color: black;
            border: 2px solid black;
        }

        button: hover {
            background-color: #a0f404;
        }

        #about-collection {
            padding: 9%;
            color: black;
            
            h1{
                font-size: 90px;
                font-family: 'Titillium Web', sans-serif;
                font-weight: bolder;
                color: #FAD5D0;
                -webkit-text-stroke: 2px #F9423A; /* width and color */

                span {
                    color: #86E1EB;
                    -webkit-text-stroke: 2px #0095E1; /* width and color */
                }
            }

            p{
                font-size: 20px;
                font-family: 'Montserrat', sans-serif !important;
                font-weight: 100 !important;
                text-align: left;
            }
        }
    }

    #social {
        margin: 20px;
        .social-icon {
            margin: 3px !important;
            width: 40px !important;
            height: 40px !important;
        }
    }

    @media ${device.tablet} { 
       #about-joey {
           #collection {                
                .column {
                    padding: 0px !important;
                    padding-left: 0px !important;
                    margin: 0px !important;
                }

                img {
                    margin: 0px !important;
                    max-width: 100% !important;
                    border-radius: 0px !important;
                    margin: 0% 0% !important;                    
                }                
           }

           button {
            font-size: 18px !important;
            }
       }

       #about-collection {
           padding: 10% 10% !important;

           h1 {
               font-size: 60px !important;
           }

           p {
               font-size: 18px !important;
           }
       }
    }

    @media ${device.laptopL}{
        #about-collection {
            h1 {
                font-size: 60px !important;
            }

            p {
                font-size: 18px !important;
            }
        }
    }
`;