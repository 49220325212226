import React from 'react';
import styled from 'styled-components'
import _ from 'underscore'

import { Grid, Container, Button, Icon } from 'semantic-ui-react';
import { device } from '../device.js';
import { Fade } from "react-awesome-reveal";

class About extends React.Component {    
    render(){
        return (
            <div className={`${this.props.className}`}>
                <Grid id="roadmap" stackable>
                    {/* <Grid.Row>
                        <Grid.Column>
                            <center className="heading">
                                <h1>Roadmap</h1>
                            </center>
                        </Grid.Column>
                    </Grid.Row> */}
                    <Grid.Row id="">                                             
                        <Grid.Column width={8}> 
                            <Fade delay={300}>
                                <center className="roadmap-stage">                             
                                    <div className="goal"><img src="/startup.png"/></div>
                                    <h1>10%</h1>
                                    <p>We've successfully launched our website and have started ramping up social media marketing.</p>                           
                                </center>
                            </Fade>
                        </Grid.Column>   
                        <Grid.Column width={8}>
                            <Fade delay={300}>                             
                                <center className="roadmap-stage">
                                    <div className="goal"><img src="/gift-box.png"/></div>
                                    <h1>25%</h1>
                                    <p>Our fans have already minted hundreds of NFTs. Top that up with awesome giveaways!</p>
                                </center>
                           </Fade>
                        </Grid.Column>  
                        <Grid.Column width={8}>
                            <Fade delay={300}> 
                                <center className="roadmap-stage">                             
                                    <div className="goal"><img src="/merch.png"/></div>
                                    <h1>50%</h1>
                                    <p>We've launched the CJ Store with trendy garments and wearables. 100 outspoken fans will each get a free item. </p>                          
                                </center>
                            </Fade>
                        </Grid.Column>   
                        <Grid.Column width={8}>  
                            <Fade delay={300}>                           
                                <center className="roadmap-stage">
                                    <div className="goal"><img src="/art.png"/></div>
                                    <h1>75%</h1>
                                    <p> A $10,000 fund will be established to support young artists through trainings and competitions.</p>
                                </center>
                           </Fade>
                        </Grid.Column>     
                        <Grid.Column width={8}>
                            <Fade delay={300}> 
                                <center className="roadmap-stage">                             
                                    <div className="goal"><img src="/writing.png"/></div>
                                    <h1>90%</h1>
                                    <p> Authenticate with proof-of-ownership and write your Joey's crazy story</p>                          
                                </center>
                            </Fade>
                        </Grid.Column>   
                        <Grid.Column width={8}> 
                            <Fade delay={300}>                            
                                <center className="roadmap-stage">
                                    <div className="goal"><img src="/money-bag.png"/></div>
                                    <h1>100%</h1>
                                    <p> 25% of royalties will be transferred to a wallet exclusively used for the betterment of the Crazy Joey community.</p>
                                </center>
                           </Fade>
                        </Grid.Column>                                               
                    </Grid.Row>                                     
                </Grid>           
            </div>
        )
    }
}

export default styled(About)`     
    #roadmap {     
        background: white;

        color: black;
        min-height: 100vh;  
        padding: 5%;

        .heading {
            h1 {
                font-size: 70px !important;
                font-family: 'Joti One', cursive;
            }
        }

        img {
            width: 100%;
            padding: 10px;
        }

        h1 {
            font-size: 50px;
            font-family: 'Titillium Web', sans-serif;
        }

        .roadmap-stage {
            max-width: 600px;
            margin: 15px;
            margin-left: auto;
            margin-right: auto;
            padding: 5%; 
            border-radius: 20px;     
            color: black;       

            .goal {
                width: 150px !important;
                padding: 5px;
                border-radius: 50%;
            }
            
            p {
                font-size: 18px;
                font-family: 'Montserrat', sans-serif;
            }           
        }

        button {
            border-radius: 25px;
            font-size: 20px;
            background-color: #ebcf34;
            color: black;
            box-shadow: 2px 5px 10px black;
            border: 2px solid black;            
        }
    }

    @media ${device.mobileL} { 
       #roadmap {
            .heading {
                h1 {
                    font-size: 50px !important;
                }
            }

            h1 {
                font-size: 40px;
            }
            
            .roadmap-stage { 
                .heading {   
                    h1 {
                        font-size: 90px !important;
                    }                 
                }

                p {
                    font-size: 15px;
                }

                .goal, img {
                    width: 120px !important;
                    height: 120px !important; 
                }
            }
            
       }
    }
`;