import React from 'react';
import styled from 'styled-components'
import _ from 'underscore';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { 
    Grid, Card, Image, Menu
} from 'semantic-ui-react';

import {Link} from 'react-router-dom'

import { device } from '../device.js';

const IMAGE_COUNT = 20;

class Gallary extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            metadata : []
        };
    }
    
    async componentDidMount(){
        window.scrollTo(0, 0);
        const metadata = [];
        for(let i =0; i< IMAGE_COUNT; i++){
            metadata.push((await(await fetch(`/${i}`)).json()))
        };
        this.setState({ metadata });
    }

    renderImages(){
        return this.state.metadata.map((metadata,i)=>{
            return (
                <Card>  
                    <Image src={metadata.image} wrapped ui={false} />
                    <center>Crazy Joey ##</center>                                     
                </Card>            
            );
        })
    }

    render(){
        console.log(this.state.metadata);
        return (
            <div className={this.props.className}>
                <div id="nav">
                    <Menu compact>
                        <Link to="/">
                            <Menu.Item as='a'>
                                <LazyLoadComponent>
                                    <Image src="/home.png"/>
                                </LazyLoadComponent>
                                <span>Back to Home</span>
                            </Menu.Item>
                        </Link>
                    </Menu>
                </div>                
                <div id='gallary'>
                    <Grid>                        
                        <Grid.Row>                            
                            <Grid.Column width={16}>
                                <Card.Group itemsPerRow={4} stackable>
                                    {this.renderImages()}
                                </Card.Group>
                            </Grid.Column>                            
                        </Grid.Row>                         
                    </Grid>    
                </div>                          
            </div>
        );
    }
}
    
export default styled(Gallary)`
    background: white;
    min-height: 100vh;

    #nav {
        padding: 40px 5%;
    }

    #gallary {
        background: white;
        padding: 40px 5%;
    }

    #cta-button {
        padding: 10px;
    }

    #cta-button button{
        border-radius: 25px;
        font-size: 20px;
        background-color: white !important;
        color: black;
        border: 2px solid black;
        animation: nudge .4s linear;
        animation-delay: 1s;
    }

    #cta-button button:hover {
        background-color: #a0f404 !important;
    }

    .card {
        border: none !important;
        box-shadow: none !important;
        margin: 15%;
    }

    .card img {
        border-radius: 20px !important;
    }

    .card .header{
        font-family: 'Joti One', cursive !important;
        font-size: 30px !important;        
    }

    .card .label {
        margin: 4px 5px;
        padding: 7px;
        box-shadow: none !important;
        border-radius: 7px;
    }

    .card .label .detail {
        color: gray;
        margin-left: 3px;
    }

    .menu .item {
        border-radius: 25px;
        font-size: 20px;
        background-color: white !important;
        color: black;
        border: 2px solid black;
        height: 70px;
    }

    .menu .item:hover {       
        background-color: #a0f404 !important;        
    }

    .menu .item img {
        width: 50px;
        margin: 5px;
    }

    .menu .item span {
        margin-left: 10px;
    }

    #filter-menu {
        background: white !important;
        height: 500px;
    }

    @media ${device.mobileL} {        
        #gallary {
            padding: 12%;
        }   
        
        #nav {
            display: none;
        }
        
        .menu {
            margin-left: auto;
            margin-right: auto;
        }
    }
`